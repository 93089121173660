<template>
  <div>
    <router-view />
  </div>
</template>

<script>
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  font-size: 0.8125rem;
}

.home .van-cell {
  background: rgb(44, 46, 85) !important;
  color: #fff;
}
.home .van-collapse-item__content{
  background: none;
}
</style>
