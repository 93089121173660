import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from "vant"
import "vant/lib/index.css"
import request from "@/config/request";
import Api from "@/config/api";
import {createI18n} from "vue-i18n";
import cn from "@/i18n/lang/cn.json"
import en from "@/i18n/lang/en.json"
import jp from "@/i18n/lang/jp.json"
import ru from "@/i18n/lang/ru.json"
import kor from "@/i18n/lang/kor.json"
import sp from "@/i18n/lang/sp.json"
import th from "@/i18n/lang/th.json"



let lang = localStorage.getItem("lang") ?? "en"
const i18n = new createI18n({
    locale: lang,
    messages: {
        en: en,
        cn: cn,
        jp: jp,
        ru: ru,
        kor: kor,
        sp: sp,
        th: th,
    }
})
const app = createApp(App);


app.config.globalProperties.$request = request;
app.config.globalProperties.$api = Api;
app.use(store).use(router).use(vant).use(i18n).mount('#app')
